var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headers,"items":_vm.notes,"loading":_vm.loading,"loading-text":"Loading... Please wait","show-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"background":"#f5f5f5"},attrs:{"elevation":"2"}},[_c('v-row',[_c('p',{staticClass:"mt-5 mb-2 ml-1 font-weight-bold d-inline-block text-truncate",staticStyle:{"max-width":"500px"}},[_vm._v(" All Notes ")]),_c('v-spacer')],1)],1)]},proxy:true},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(new Date(item.created_at).toLocaleString()))])]}},{key:"item.attended",fn:function(ref){
var item = ref.item;
return [(item.attended)?_c('p',{staticClass:"mb-0"},[_vm._v("Attended")]):_c('p',{staticClass:"mb-0"},[_vm._v("Not Attended")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('p',{staticClass:"mb-0 pa-5"},[_vm._v(_vm._s(item.content))])])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"padding-left":"0px !important"},attrs:{"color":"primary","text":"","icon":""},on:{"click":function($event){return _vm.viewInvoice(item)}}},[_c('v-icon',[_vm._v(" mdi-eye")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }