<template>
  <v-container>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="notes"
          class="elevation-5"
          :loading="loading"
          loading-text="Loading... Please wait"
          show-expand
        >
          <template v-slot:top>
            <v-toolbar elevation="2" style="background: #f5f5f5">
              <v-row>
                <p
                  class="mt-5 mb-2 ml-1 font-weight-bold d-inline-block text-truncate"
                  style="max-width: 500px"
                >
                  All Notes
                </p>
                <v-spacer></v-spacer>
              </v-row>
            </v-toolbar>
          </template>

          <template v-slot:item.time="{ item }">
            <p class="mb-0">{{ new Date(item.created_at).toLocaleString() }}</p>
          </template>

          <template v-slot:item.attended="{ item }">
            <p v-if="item.attended" class="mb-0">Attended</p>
            <p v-else class="mb-0">Not Attended</p>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-container>
                <p class="mb-0 pa-5">{{ item.content }}</p>
              </v-container>
            </td>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              color="primary"
              style="padding-left: 0px !important"
              text
              @click="viewInvoice(item)"
              icon
              ><v-icon> mdi-eye</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: true,
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "id",
          class: "text-subtitle-2 black--text",
        },
        {
          text: "Invoice",
          value: "invoice_id",
          class: "text-subtitle-2 black--text",
        },
        {
          text: "Company Name",
          value: "company_name",
          class: "text-subtitle-2 black--text",
        },
        {
          text: "Created",
          value: "time",
          class: "text-subtitle-2 black--text",
        },
        {
          text: "Status",
          value: "attended",
          class: "text-subtitle-2 black--text",
        },
        {
          text: "Details",
          value: "actions",
          class: "text-subtitle-2 black--text",
        },
        { text: "", value: "data-table-expand" },
      ],
      notes: [],
      snackbar: false,
      message: "",
    };
  },
  methods: {
    ...mapActions({
      get_company: "company/get_company",
    }),

    viewInvoice(note) {
      // Pass to the invoice page
      this.$router.push(`/supportdashboard/pages/orderinvoice/?id=${note.invoice_id}`);
    },
  },
  created() {
    // GET all notes
    axios
      .get("https://server.support24.com.bd/get_all_notes")
      .then((response) => {
        if (response.data.success) {
          this.notes = response.data.all_notes_result;
          this.loading = false;
        } else {
          this.messsage = "Something went wrong !";
          this.snackbar = true;
          this.loading = false;
        }
      })
      .catch((err) => {
        console.log(err.message);
        this.loading = false;
        // handle error
      });
  },
};
</script>

<style>
</style>